<template>
    <div></div>
</template>

<script>
import { authChecker, utils } from '@/common/js/mmcModule';
import mmcAjax from "@/common/js/mmcAjax"
export default {
    created() {
        authChecker.check({ notneedphone: 0 }, (res) => {
            let cid = utils.getRequestParams().counselor_id;
            if (cid) {
                let url = `${window.APIBasePath}mmc/user-center/counselor/bind`;
                mmcAjax({
                    method: 'POST',
                    url,
                    data:{
                        counselor_id:Number(cid)
                    },
                    showMessage: false,
                }).then(res=>{
                    this.$router.replace('/fund/publish/lead')
                })
            }
        });
    },
};
</script>

<style>
</style>